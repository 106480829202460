import { defineStore } from 'pinia';

export const useAddressesStore = defineStore('addresses', {
    state: () => {
        return {
            addresses: {},
            countries: [], // Maunt specific
            isAddressesStoreFilled: false,
            isLoggedIn: false,
            defaultBillingAddress: {},
            firstName: '',
        };
    },
    actions: {
        removeAddress(address) {
            this.addresses.forEach((item, key) => {
                if (item.id === address.id) {
                    this.addresses.splice(key, 1);
                }
            });
        },
        updateAddressesWithOne(address) {
            let addressEdited = false;
            this.addresses.forEach((item, key) => {
                if (item.id === address.id) {
                    this.addresses[key] = address;
                    addressEdited = true;
                }
            });

            if (!addressEdited) {
                address.id = this.addresses.length + 1; // Random ID for new addresses
                this.addresses.push(address);
            }
        },
        async fillPrimaryStores() {
            try {
                this.isAddressesStoreFilled = false;
                const response = await fetch('/api/dashboard_main');

                if (!response.ok) {
                    throw new Error('Failed to fetch recent order data');
                }

                const result = await response.json();
                this.addresses = result.addresses.reverse();
                this.countries = result.countries;
                this.firstName = result.account_data.first_name;

                this.isAddressesStoreFilled = true;
                this.isLoggedIn = true;
                this.defaultBillingAddress = result.account_data.default_addresses.billing;
            } catch (error) {
                this.isAddressesStoreFilled = false;
            }
        },
        async fillCountries() {
            try {
                const response = await fetch('/api/get/countries');

                if (!response.ok) {
                    throw new Error('Failed to fetch recent order data');
                }

                const result = await response.json();
                this.countries = result;

                this.isAddressesStoreFilled = true;
            } catch (error) {
                this.isAddressesStoreFilled = false;
            }
        },
    },
});

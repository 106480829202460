<script setup>
  import { ref } from "vue";
  import { useFiltersStore } from "../../stores/filters";
  import RBMImage from "../Utility/RBMImage.vue";
</script>

<template>
  <div class="slider slider--main-categories" v-if="!useFiltersStore().filtersIsLoading && useFiltersStore().mainItem.children.length > 0">
    <div class="slider__items">
        <div class="slider__swiper swiper swiper--buttons">
            <div class="slider__swiper-wrapper swiper-wrapper">
              <div class="category--horizontal swiper-slide" v-for="categoryOptions in useFiltersStore().mainItem.children" :key="categoryOptions.id">
                  <router-link class="category__content" :to="'/' + categoryOptions.slug">
                    <RBMImage :image="categoryOptions.filter_image" />
                    <span>{{ categoryOptions.title }}</span>
                  </router-link>
              </div>
            </div>

            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
      </div>
  </div>
  <div v-else-if="useFiltersStore().filtersIsLoading" class="slider slider--main-categories">
    <div class="slider__items">
      <div class="slider__swiper swiper swiper--buttons">
        <div class="slider__swiper-wrapper swiper-wrapper">
          <div class="category--horizontal category--horizontal-skeleton swiper-slide" v-for="index in 4" :key="index">
            <div class="category__content">
              <div class="content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
	@import '../../../sass/elements/skeletons.scss';

.category--horizontal {
    .category__content {
      @apply border border-solid border-border rounded w-full flex items-center min-h-[64px] px-[14px] text-15_20_14_20 text-dark font-fontText transition-colors duration-300 gap-1.5;
      @apply xs:min-h-[40px] xs:px-1.5;
      @apply hover:border-primary-600;

      .content {
        @apply max-w-[35px]
      }
    }

    &.category--horizontal-skeleton {
      .category__content {
        @extend .skeleton;
        @apply pointer-events-none border-0 h-[64px];       
        @apply xs:h-[40px];

        > * {
           @apply opacity-0 pointer-events-none;
        }
      }
    }
}
</style>

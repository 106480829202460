import { defineStore } from 'pinia';

export const useFiltersStore = defineStore('filters', {
    state: () => {
        return {
            totalItems: 0,
            items: [],
            filters: null,
            mainItem: null,
            filtersIsLoading: false,
        };
    }, actions: {
        setFilters(object) {
            console.log(object);
            this.filtersIsLoading = true;

            if ('totalItems' in object && object.totalItems === object.items.length) {
                this.totalItems = object.totalItems;
            } else {
                this.totalItems =  object.items.length;
            }

            this.items = object.items;

            object.filters.options = Object.values(object.filters.options).map((option) => {
                option.values = Object.values(option.values).sort((a, b) => {
                    return (a.active === b.active)? 0 : a.active? -1 : 1;
                });

                return option;
            });

            this.filters = object.filters
            this.mainItem = object.item;
            this.filtersIsLoading = false;
        },
        setItems(items) {
            this.items = items;
            this.totalItems = items.length;
        },
        async updateFiltersByUrl(url) {
            this.filtersIsLoading = true;

            if (!url.includes(window.location.origin)) {
                url = window.location.origin + url;
            }

            const options = {
                headers: {
                    'Accept': 'application/json',
                }
            }
            const response = await (await fetch(url, options)).json();

            if (response) {
                this.setFilters(response);
            }

            this.filtersIsLoading = false;            
        }
    }
});

<script setup>
import { useFiltersStore } from "../../stores/filters";
import ProductsItems from "../Products/ProductsItems.vue";
import ProductsFilters from "./ProductsFilters.vue";
import ProductsSwiperFilters from "./ProductsSwiperFilters.vue";
</script>

<template>
  <!-- TO DO HARDCODED TEXT -->
  <section class="products" aria-label="Producten overzicht met filters">
    <div class="products__container">
      <section
        class="products__header"
        aria-labelledby="products__header-title"
      >
        <div class="products__header-content">
          <h1 id="products__header-title">
            {{ useFiltersStore().mainItem?.title }}
          </h1>
          <p v-if="useFiltersStore().mainItem?.description || useFiltersStore().mainItem?.meta_description">
            {{
              useFiltersStore().mainItem?.description ??
              useFiltersStore().mainItem?.meta_description
            }}
          </p>
        </div>
        <ProductsSwiperFilters />
      </section>
      <aside class="products__filters" aria-label="Product filters">
        <div class="products__filters--desktop">
            <ProductsFilters />
        </div>
        <div class="product__filters--mobile">
            <button class="filters__button">
                <!-- TO DO HARDCODED TEXT -->
                <i class="fa-regular fa-sliders"></i> Producten filteren
            </button>
            <div class="filters__navigation">
                <div class="filters__navigation-head">
                    <!-- TO DO HARDCODED TEXT -->
                    <span class="filters__navigation-title">Filteren</span>
                    <div class="filters__navigation-closer" data-id="nav-closer" aria-expanded="false" aria-controls="mobile-menu">
                        <i class="fa-light fa-xmark"></i>
                    </div>
                </div>
                <div class="filters__navigation-content">
                    <ProductsFilters />
                </div>
            </div>
        </div>
      </aside>

      <ProductsItems />
    </div>
  </section>

  <!-- TO DO HARDCODED TEXT -->
  <section v-if="useFiltersStore().mainItem?.paragraphs?.length > 0" class="paragraphs paragraphs--products" aria-label="Paragrafen">
    <div class="paragraphs__container">
      <div class="paragraphs__grid paragraphs__grid--full">
        <div v-for="paragraph in useFiltersStore().mainItem.paragraphs" class="paragraphs__item paragraphs__item--text">
          <h2>{{ paragraph.title }}</h2>
          <p>
           {{ paragraph.content}}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.products{
    @apply mb-20;
    @apply md:mb-9;
}
.products__container {
  @apply container grid grid-cols-12 gap-3;
  @apply lg:px-6;
  @apply sm:px-4;
}

.products__filters {
  @apply col-span-3 row-start-1 row-end-4 col-start-1;
  @apply md:col-span-12 md:row-start-auto md:row-end-auto md:col-start-auto;
}

.products__header {
  @apply col-span-9 row-start-1 row-end-2 col-start-4 mb-[18px];
  @apply md:col-span-12 md:row-start-auto md:row-end-auto md:col-start-auto md:mb-3;;

  h1 {
    @apply text-32_32_20_20 font-fontText font-bold text-black;

    + p {
      @apply mt-5;
      @apply md:mt-2;
    }
  }

  p {
    @apply text-18_28_14_20 text-text;
  }
}

.products__header-content {
  &:has(+ .slider--main-categories) {
    @apply pb-8;
    @apply md:pb-4
  }
}

.products__filters--desktop {
    @apply md:hidden;
}

.product__filters--mobile {
    @apply hidden;
    @apply md:block;
}
</style>

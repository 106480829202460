<script setup>
import {ref} from "vue";
  import { useWishlistStore } from "../../stores/wishlist.js";

  const wishlistStore = useWishlistStore();

  const props = defineProps({
    item: Object,
  });

  const isChecked = ref(!!wishlistStore.findWishlistProductById(props.item.id));

  const toggleWishlistState = async () => {
    if(!isChecked.value) {
      await wishlistStore.addItemToWishlist(props.item);
    } else {
      await wishlistStore.deleteItemInWishlist(props.item);
    }

    isChecked.value = !!wishlistStore.findWishlistProductById(props.item.id);
  };
</script>

<template>
  <button
    class="wishlist__button notranslate"
    :class="{ 'wishlist__button--active': isChecked }"
    @click="toggleWishlistState()"
  >
    <i class="fa-light fa-heart"></i>
  </button>
</template>

<style lang="scss" scoped>
.wishlist__button {
  @apply h-10 w-10 z-10 rounded-full border border-solid border-border flex items-center justify-center text-primary-600 text-[18px] transition-colors duration-300 relative bg-white;
  @apply hover:bg-[#FAF2E6];

  i {
    &::after {
      @apply content-["\f004"] font-bold opacity-0 transition-opacity duration-300 absolute inset-0 flex items-center justify-center;
    }
  }
}

.wishlist__button--active {
  @apply bg-[#FAF2E6] border-primary-500;
  @apply hover:bg-white;

  i {
    &::after {
      @apply opacity-100;
    }
  }
}
</style>

<script setup>
  import ProductCard from '../../components/ProductCard.vue';
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";

  const props = defineProps({
    products: Object
  });
</script>

<template>
  <div class="dashboard-cart__content">
    <div class="dashboard-cart__main">
      <div class="dashboard-cart__slider slider slider--dashboard">
        <div class="slider__items">
          <div class="slider__swiper swiper swiper--buttons-sibling swiper--vue">
            <div class="slider__swiper-wrapper swiper-wrapper">
              <ProductCard
                v-for="product in products"
                :product="product.product"
                :isProfileItem=true
                :show-skeleton=false
              />
            </div>
          </div>
          <div class="slider__buttons swiper__buttons">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
      <div class="dashboard-cart__checkout">
        <a class="btn-solid--primary-600" :href="appendLanguagePrefixURL('/afrekenen')">Bestellen</a>
      </div>
    </div>
  </div>
</template>

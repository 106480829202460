<script setup>
  import { watch, onBeforeMount, onMounted, ref } from 'vue'
  import QuantitySelector from '../components/Utility/QuantitySelector.vue';
  import RBMImage from '../components/Utility/RBMImage.vue';
  import { useForm } from '@tanstack/vue-form'

  import { useQuotationStore } from "../stores/quotation";
  import { useSetNewSwipers } from '../composables/useSetNewSwipers.js';

  import { useDialog } from "../composables/useDialog.js";
  import { validatePhoneNumber } from '../includes/validatePhoneNumber.js';

  import AddressesDialog from "../components/Utility/Dialogs/Addresses.vue";
  import { appendLanguagePrefixURL } from "@/mixins/appendLanguageURL";
	import RecaptchaField from "../components/Utility/RecaptchaField.vue";

  const setNewSwipers = useSetNewSwipers();

  const loggedin = ref(false);

  const props = defineProps({
    url: String,
    data: Object,
    privacyUrl: String,
    tocUrl: String
  });

  const product = ref(null);
  const productAmount = ref(1);
  const loading = ref(true);
  const togglePasswordFields = ref(false);
  const quotationPageFilled = ref(false);

  const addresses = ref({});

  const succesNotification = ref(false);

  const defaultPhonenumber = ref(useQuotationStore().accountData?.phone_number);

  onBeforeMount(async () => {
    useQuotationStore().fillPrimaryStores();
    if(loggedin === true) {
      setNewSwipers();
      defaultPhonenumber.value = useQuotationStore().accountData?.phone_number;
    }
  });

  watch(
    () => useQuotationStore().isLoggedIn,
    (newVal) => {
      if (newVal) {
        if(useQuotationStore().isLoggedIn !== false) {
            loggedin.value = true;
            setNewSwipers();
            defaultPhonenumber.value = useQuotationStore().accountData?.phone_number;
        } else {
          loggedin.value = false;
        }
      }
    },
    { immediate: true, deep: true }
  );

  watch(
    () => useQuotationStore().isQuotationStoreFilled,
    (newVal) => {
      if (newVal) {
        if(useQuotationStore().addresses.length > 0) {
          setNewSwipers();
          defaultPhonenumber.value = useQuotationStore().accountData?.phone_number;

          if(useQuotationStore().defaultShippingAddress) {
            addresses.value = {'shipping' : useQuotationStore().defaultShippingAddress};
          }
        }
        quotationPageFilled.value = true;

        if(useQuotationStore().isLoggedIn !== false) {
            loggedin.value = true;
            loading.value = false
            setNewSwipers();
            defaultPhonenumber.value = useQuotationStore().accountData?.phone_number;
        } else {
          loggedin.value = false;
          loading.value = false
        }
      }
    },
    { immediate: true, deep: true }
  );

  watch(
    () => addresses.value?.shipping?.id,
    (newVal) => {
      if (newVal) {
        let newAddress = useQuotationStore()?.addresses.find((address) => address.id == newVal);
        addresses.value.shippingFromStore = newAddress;
      }
    },
    { immediate: true, deep: true }
  );

  const findCountryById = (id) => {
    return useQuotationStore()?.countries.find((country) => country.id == id);
  }

  const fillProductAmount = (amount) => {
    productAmount.value = amount;
  }

  const setAddress = (address, type) => {
    addresses.value = {'shipping' : address};
  }

  const openAddressesDialog = async () => {
    const isMobile = ref(false);

    isMobile.value = window.innerWidth < 768;

    if (isMobile.value) {
      useDialog().setPosition('left');
    } else {
      useDialog().setPosition('top');
    }

    useDialog().open(AddressesDialog, {'cartAddresses': addresses, 'addresses': useQuotationStore().addresses, 'countries': useQuotationStore().countries});
  };

  const form = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      company_name: '',
      phone_number: '',
      phone_number_account: '',
      email: '',
      // invoice_same_as_shipping: true,
      product_amount: productAmount.value,
      product_id: '',
      remark: '',
      addresses: {
        shipping: {
          street: '',
          number: '',
          house_number_addition: '',
          zip: '',
          city: '',
          country_id: ''
        },
      },
      privacy_statement: false,
    },
    onSubmit: async ({ value }) => {
      if(loggedin.value) {
        value.addresses.shipping = addresses.value.shipping;

        if(!Object.values(value.addresses.shipping).includes("street")) {
          value.addresses.shipping = addresses.value.shippingFromStore;
        }

        value.first_name = useQuotationStore().accountData?.first_name;
        value.last_name = useQuotationStore().accountData?.last_name;
        value.email = useQuotationStore().accountData?.email_address;
        value.company_name = useQuotationStore().accountData?.contact_name;
        if(value.phone_number === '') {
          value.phone_number = useQuotationStore().accountData?.phone_number;
        }
      } else {
        value.addresses.shipping.country = findCountryById(value.addresses.shipping.country).name;
      }

      value.product_id = product.value.id;
      value.recaptcha_response = recaptchaToken.value;

      let hashedEmail = await window.crypto.subtle.digest("SHA-256", (new TextEncoder()).encode(value.email));
      let hashedPhoneNumber = await window.crypto.subtle.digest("SHA-256", (new TextEncoder()).encode(value.phone_number));
      hashedEmail = Array.prototype.map.call(new Uint8Array(hashedEmail), x => ('00' + x.toString(16)).slice(-2)).join('');
      hashedPhoneNumber = Array.prototype.map.call(new Uint8Array(hashedPhoneNumber), x => ('00' + x.toString(16)).slice(-2)).join('');
      let data = {
        "data": {
          "page": "quotation", "fields": {
            "normal": {
              "email": value.email, "phone_number": value.phone_number
            }, "hashed": {
              "email": hashedEmail,
              "phone_number": hashedPhoneNumber
            }
          }
        }
      };
      sendDataLayers("form_completion", data);

      fetch('/quotation/submit', {
        method: 'POST',
        body: JSON.stringify(value)
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            succesNotification.value = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            captcha.value.refresh();
            succesNotification.value = false;
          }
        })
        .catch(error => {
          // Handle error
          captcha.value.refresh();
        });
    },
  })

  onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');

    if (productId) {
      fetch(`/api/product/${productId}`)
      .then(response => response.json())
      .then(data => {
        product.value = data;
      })
      .catch(error => {
        console.error('Error fetching product:', error);
      });
    }
  })

  const recaptchaToken = ref(null);
  const captcha = ref(null);
</script>

<template>
  <section class="quotation" aria-labelledby="quotation__title">
    <h1 id="quotation__title">Offerte aanvragen</h1>

    <div id="notification" v-if="succesNotification" class="form__notification form__notification--success">
      <span>Bedankt voor het aanvragen van een offerte. We nemen zo snel mogelijk contact met je op.</span>
    </div>

    <div class="quotation-product" v-if="quotationPageFilled && !loading && !succesNotification">
      <div class="quotation-product__image" v-if="product?.main_image">
        <RBMImage :image="product.main_image" />
      </div>
      <div class="quotation-product__content">
        <span class="quotation-product__title notranslate">
            {{ product?.title }}
        </span>
        <span class="quotation-product-stock" v-if="product?.amount_in_stock > 5">
          <i class="fa-solid fa-circle-check"></i>
          {{ product?.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
        </span>
        <span
          class="quotation-product-stock--low"
          v-else-if="product?.amount_in_stock > 0"
        >
          <i class="fa-solid fa-circle-check"></i>
          {{ product?.amount_in_stock }} {{ product.unit.toLowerCase() }} op voorraad
        </span>
        <span class="quotation-product-stock--outofstock" v-else> <!-- TODO: Hardcoded text -->
          <i class="fa-solid fa-circle-xmark"></i> Niet op voorraad
        </span>
        <QuantitySelector @updated-quantity="fillProductAmount" />
      </div>
    </div>

    <div class="dashboard-skeleton--block" v-if="!quotationPageFilled && loading">
      <!-- -->
    </div>
    <div class="quotation-address" v-else-if="loggedin && !succesNotification">
      <span class="quotation-form__title">
        Adres en gegevens
      </span>

      <div class="slider slider--shipping-addresses" v-if="useQuotationStore().checkoutStoreIsloading !== true">
        <div class="slider__items">
            <div class="slider__swiper swiper swiper--buttons swiper--vue" :class="{ 'swiper--slide-active' : useQuotationStore().addresses.length > 2 }">
                <div class="slider__swiper-wrapper swiper-wrapper">
                    <div class="swiper-slide" v-for="(address, index) in useQuotationStore().addresses" :key="index" :class="{'active-slide' : address.id === addresses?.shipping?.id}" :data-nr="index">
                      <div class="address__content"
                            @click="setAddress(address, 'shipping')"
                            :class="{'address__content--active': address.id === addresses?.shipping?.id}"
                      >
                        <div class="address__inner">
                          <strong class="notranslate">{{ address.title }}</strong>
                          <span class="notranslate">{{ address.first_name }} {{ address.last_name }}</span>
                          <span class="notranslate">{{ address.street }} {{ address.house_number }} {{ address.house_number_addition }}</span>
                          <span class="notranslate">{{ address.zip }} {{ address.city }}</span>
                          <span v-if="address?.country_id">{{ findCountryById(address.country_id).name }}</span>
                          <span v-else-if="address?.country">{{ address.country }}</span>
                        </div>
                      </div>
                    </div>
                </div>
              <div class="slider__buttons swiper__buttons">
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
              </div>
            </div>
        </div>
      </div>
      <div class="slider--skeleton" v-else><!-- --></div>
      <button class="button__change-address btn-text--primary-600" @click="openAddressesDialog(useQuotationStore().addresses)" v-if="useQuotationStore().checkoutStoreIsloading !== true">Wijzig of voeg nieuw adres toe</button>
      <span class="button--skeleton" v-else><!-- --></span>
      <span class="slider--skeleton"  v-if="useQuotationStore().checkoutStoreIsloading === true"><!-- --></span>

      <span class="quotation-form__title">
        Contactgegevens
      </span>

      <div class="form__wrapper">
        <form @submit="
        (e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }
        ">
        <div class="input input--single" v-if="defaultPhonenumber">
          <div class="input-inner">
            <label for="phone_number_account" class="form__label">Telefoonnummer*</label>
            <form.Field
              name="phone_number_account"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="defaultPhonenumber"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value.replace(/[^0-9+\-()]+/g, ''))"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                inputmode="numeric" 
                disabled
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single" v-else>
          <div class="input-inner">
            <label for="phone_number" class="form__label">Telefoonnummer*</label>
            <form.Field
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value.replace(/[^0-9+\-()]+/g, ''))"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                disabled
                inputmode="numeric"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <span class="quotation-form__title quotation-form__title--big-margin">
          Eventuele opmerkingen
        </span>

        <div class="input input--single input--full input--textarea">
          <div class="input-inner">
            <label for="remark" class="form__label">Jouw opmerkingen</label>
            <form.Field
              name="remark"
            >
              <template v-slot="{ field }">
                <textarea
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single input--full">
          <div class="input-inner">
            <div class="input-inner__checkbox input-inner__checkbox--with-error">
              <label for="privacy_statement" class="input-container">
                <form.Field name="privacy_statement"
                  :validators="{
                    onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
                  }">
                  <template v-slot="{ field }">
                    <div>
                      <input
                        type="checkbox"
                        :name="field.name"
                        :value="field.state.value"
                        @blur="field.handleBlur"
                        id="privacy_statement"
                        @input="field.handleChange($event.target.checked)"
                        class="checkbox__fix-input"
                        :class="{'input--invalid': field.state.meta.errors.length > 0}"
                        required
                      >
                      <span class="checkbox__fix-checkbox"></span>
                      <span>Ik ga akkoord met de <a :href="privacyUrl" target="_blank">privacy statement</a> en <a :href="tocUrl" target="_blank">algemene voorwaarden</a> van Maunt en de verwerking van mijn gegevens.</span>
                    </div>
                    <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
                  </template>
                </form.Field>
                </label>
            </div>
          </div>
        </div>

        <RecaptchaField ref="captcha" v-model="recaptchaToken"></RecaptchaField>

        <div class="form__submit">
          <div class="form__submit-inner">
            <div class="form__submit-button form__submit-button--right">
              <button type="submit" class="btn-solid--primary-600" :class="{disabled : !addresses.shipping}">
                Offerte aanvragen
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>

    </div>    
    <div class="quotation-form" v-else-if="!loggedin && !succesNotification">
      <span class="quotation-form__title">
        Persoonlijke gegevens
      </span>

      <div class="form__wrapper">
        <form @submit="
        (e) => {
          e.preventDefault()
          e.stopPropagation()
          form.validate()
          form.handleSubmit()
        }
        ">
        <div class="input">
          <div class="input-inner">
            <label for="first_name" class="form__label">Voornaam*</label>
            <form.Field
              name="first_name"
              :validators="{
                onChange: ({ value }) =>
                  !value
                    ? `Dit veld is verplicht`
                    : undefined,
                }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input">
          <div class="input-inner">
            <label for="last_name" class="form__label">Achternaam*</label>
            <form.Field
              name="last_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
              >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="company_name" class="form__label">Bedrijfsnaam*</label>
            <form.Field
              name="company_name"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="text"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="phone_number" class="form__label">Telefoonnummer*</label>
            <form.Field
              name="phone_number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="tel"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value.replace(/[^0-9+\-()]+/g, ''))"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                inputmode="numeric"             
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single">
          <div class="input-inner">
            <label for="email" class="form__label">Email*</label>
            <form.Field
              name="email"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                type="email"
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <span class="quotation-form__title">
          Adresgegevens
        </span>

        <div class="input input--single">
          <div class="input-inner">
            <label for="country" class="form__label">Land*</label>
            <form.Field name="addresses.shipping.country"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }">
              <template v-slot="{ field }">
                <select
                  :name="field.name"
                  :value="field.state.value"
                  @blur="field.handleBlur"
                  @input="(e) => {
                    field.handleChange(e.target.value);
                  }"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                >
                  <option value="" disabled>Selecteer een land</option>
                  <option v-for="country in useQuotationStore().countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                  <!-- Add more countries here -->
                </select>
              </template>
            </form.Field>
          </div>
        </div>

        <div class="input input--address">
          <div class="input-inner input-inner--col-6">
            <label for="address" class="form__label">Straatnaam*</label>
            <form.Field
              name="addresses.shipping.street"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="address-number" class="form__label">Huisnr.*</label>
            <form.Field
              name="addresses.shipping.house_number"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner">
            <label for="address-addition" class="form__label">Toevoeging</label>
            <form.Field
              name="addresses.shipping.house_number_addition"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--city">
          <div class="input-inner ">
            <label for="address-postal" class="form__label">Postcode*</label>
            <form.Field
              name="addresses.shipping.zip"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
          <div class="input-inner input-inner--col-6">
            <label for="address-city" class="form__label">Plaatsnaam*</label>
             <form.Field
              name="addresses.shipping.city"
              :validators="{
                onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
              }"
            >
              <template v-slot="{ field }">
                <input
                  type="text"
                  :name="field.name"
                  :value="field.state.value || ''"
                  @blur="field.handleBlur"
                  @input="(e) => field.handleChange(e.target.value)"
                  :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <span class="quotation-form__title quotation-form__title--big-margin">
          Eventuele opmerkingen
        </span>

        <div class="input input--single input--full input--textarea">
          <div class="input-inner">
            <label for="remark" class="form__label">Jouw opmerkingen</label>
            <form.Field
              name="remark"
            >
              <template v-slot="{ field }">
                <textarea
                :name="field.name"
                :value="field.state.value"
                @blur="field.handleBlur"
                @input="(e) => field.handleChange(e.target.value)"
                :class="{'input--invalid': field.state.meta.errors.length > 0}"
                />
                <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
              </template>
            </form.Field>
          </div>
        </div>
        <div class="input input--single input--full">
          <div class="input-inner">
            <div class="input-inner__checkbox input-inner__checkbox--with-error">
              <label for="privacy_statement" class="input-container">
                <form.Field name="privacy_statement"
                  :validators="{
                    onBlur: ({ value }) => !value ? 'Dit veld is verplicht' : null,
                  }">
                  <template v-slot="{ field }">
                    <div>
                      <input
                        type="checkbox"
                        :name="field.name"
                        :value="field.state.value"
                        @blur="field.handleBlur"
                        id="privacy_statement"
                        @input="field.handleChange($event.target.checked)"
                        class="checkbox__fix-input"
                        :class="{'input--invalid': field.state.meta.errors.length > 0}"
                        required
                      >
                      <span class="checkbox__fix-checkbox"></span>
                      <span>Ik ga akkoord met de <a :href="privacyUrl" target="_blank">privacy statement</a> en <a :href="tocUrl" target="_blank">algemene voorwaarden</a> van Maunt en de verwerking van mijn gegevens.</span>
                    </div>
                    <span role="alert" v-if="field.state.meta.errors">{{ field.state.meta.errors.join(', ') }}</span>
                  </template>
                </form.Field>
                </label>
            </div>
          </div>
        </div>

        <RecaptchaField ref="captcha" v-model="recaptchaToken"></RecaptchaField>

        <div class="form__submit">
          <div class="form__submit-inner">
<!--            TODO, reset alreadyLoggedIn-->
            <div class="form__submit-login">Heb je al een account? <a :href="appendLanguagePrefixURL('/login')" class="btn-text--primary-600">Inloggen op jouw account</a></div>
            <div class="form__submit-button">
              <button type="submit" class="btn-solid--primary-600">
                Offerte aanvragen
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '../../sass/elements/skeletons.scss';

  .form__notification {
    @apply col-span-10 col-start-2;
    @apply lg:col-span-12;
  }

  .quotation {
    @apply container grid grid-cols-12 gap-3 pt-9 mb-[80px] overflow-hidden;
    @apply lg:px-6;
    @apply sm:px-4;
    @apply mob:mb-9;
    @apply xs:gap-0 xs:pt-6;

    h1 {
      @apply font-bold text-32_32_20_20 font-fontText col-span-10 col-start-2 mb-6;
      @apply lg:col-span-12;
      @apply mob:mb-3;
    }
  }

  .quotation-product {
    @apply border-y border-solid border-border col-span-10 col-start-2 py-7 mb-9 flex gap-x-8;
    @apply lg:col-span-12 lg:grid-cols-12;
    @apply xs:flex xs:flex-col xs:pt-0;
  }

  .quotation-form {
    @apply col-span-10 col-start-2;
    @apply lg:col-span-12;
  }

  .quotation-product__title {
    @apply text-17_28_14_22 text-black mb-0 block;
  }

  .quotation-product-stock {
    @apply text-14_16_12_14 font-bold mb-4 block text-green;
  }

  .quotation-product-stock--outofstock {
    @extend .quotation-product-stock;
    @apply text-[#B72D3B];
  }

  .quotation-product-stock--low {
    @extend .quotation-product-stock;
    @apply text-[#DE8C00];
  }

  .quotation-form__title {
    @apply block text-24_30_17_20 font-bold font-fontText mb-6 col-span-10;
  }

  .quotation-form__title--big-margin {
    @apply mt-[20px];
    @apply xs:mt-0;
  }

  .quotation-product__content {

  }

  .quotation-product__image {
    @apply flex-[0_0_91px];
  }

  input {
    &.input--invalid {
      @apply border-red-500;
    }
  }

  .quotation-address {
    @apply col-span-10 col-start-2;
    @apply lg:col-span-12;
  }

  /* BEGIN ADDRESSES STYLING */
  .slider--shipping-addresses, .slider--billing-addresses {
    @apply pt-0;
  }

  .address__content {
    @apply bg-white py-8 px-10 rounded border border-solid border-border transition-all duration-300 cursor-pointer h-full;
    @apply sm:p-4;
    @apply hover:border-[#CCA666];

    &--active {
      @apply bg-[#FAF2E6] border-[#CCA666];
    }
  }

  .address__inner {
    @apply flex flex-col;
  }

  .button__change-address {
    @apply font-normal text-15_20_14_20 mb-9;
    @apply md:mb-2;
  }

  .form__submit-inner {
    @apply gap-1.5;
  }

  .form__submit-button {
    &.form__submit-button--right {
      @apply w-full flex mt-3;
    }

    button {
      @apply cursor-pointer ml-auto;

      &.disabled {
        @apply pointer-events-none;
      }
    }
  }

  .dashboard-skeleton--block {
    @extend .skeleton;
    @apply h-[750px] w-full col-span-10 col-start-2;
    @apply lg:col-span-12;
  }

    /* END ADDRESSES ENDSTYLING */
</style>

<!-- TODO: Make it impossible to get to this page directly without product id parameter. If this happens, redirect to /webshop -->
<!-- TODO: Make a request to the API, passing through the product id in the url parameter and fetch the information of this product. -->
<!-- TODO: Check if an user is logged in, if this is the case the display the available adresses. Otherwise, display a form -->


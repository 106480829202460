<script setup>
import RBMImage from "../Utility/RBMImage.vue";
import {appendLanguagePrefixURL} from "@/mixins/appendLanguageURL";

const props = defineProps({
  category: Object,
});
</script>

<template>
  <article class="categories__item">
    <a :href="appendLanguagePrefixURL(category.slug)">
    <div class="categories__item-content">
      <div class="categories__item-media">
        <figure class="asp-base">
          <RBMImage :image="category.image" size="small" />
        </figure>
      </div>
      <div class="categories__item-text">
        <span class="categories__item-title">{{ category.title }}</span>
      </div>
    </div>
    </a>
  </article>
</template>


<style lang="scss" scoped>
.categories__item {
  @apply col-span-2;
  @apply lg:col-span-3;
  @apply md:col-span-4;
  @apply mob:col-span-6;

  >a {
    &:hover {
      .categories__item-content {
        .categories__item-title {
           @apply text-primary-600;
        }
      }
    }
  }
}

.categories__item-content {
  @apply relative bg-white rounded border border-solid border-border flex flex-col transition-colors duration-300 h-full;
}

.categories__item-media {
  @apply aspect-[230_/_155] relative rounded-t overflow-hidden pt-1 px-9;

  &::before {
    @apply content-[""] absolute inset-x-0 top-0 bottom-12 bg-lightGrey w-screen;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6.4vw), 0 100%);
  }

  .asp-base {
    @apply h-full;

    .content {
      @apply relative;
    }

    img {
      @apply h-full w-full object-contain mix-blend-multiply;
    }
  }
}

.categories__item-text {
  @apply px-4 text-center pb-7;
  @apply sm:px-2 sm:pb-5;
}

.categories__item-title {
  @apply font-fontText font-bold text-18_21_14_21 text-black transition-colors duration-300;
}
</style>

<script setup>
  import { useDialog } from "../../composables/useDialog";
  import Dialog from 'primevue/dialog';
</script>

<template>
  <Dialog
      v-model:visible="useDialog().visible.value"
      modal
      :position="useDialog().position.value"
      :draggable="false"
      :dismissableMask="true"
      :showHeader="false"
      :baseZIndex="99999"
  >
    <div v-if="useDialog().tpl.value === null">No template set</div>
    <component v-else :is="useDialog().tpl.value" :data="useDialog().tplData.value"/>
  </Dialog>
</template>

<style lang="scss">
:root {
  --p-mask-background:  rgba(0, 0, 0, .6);
}

.p-dialog-mask {
  @apply overflow-auto;
  backdrop-filter: blur(2px);

  .p-dialog {
    @apply rounded overflow-auto max-h-max min-h-full m-0;
    @apply sm:max-h-full sm:h-full sm:flex-row sm:p-0 sm:rounded-none;

    @media screen and (max-width: 768px) {
     margin: 0 !important;
    }

    .p-dialog-content {
      @apply rounded m-auto bg-white;
      @apply sm:rounded-none sm:h-full;

      .dialog__content {
        @apply relative;
        @apply sm:h-full;
      }
    }
  }
}
</style>
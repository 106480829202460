<script setup>
import { ref } from "vue";
import { useDialog } from "../../../composables/useDialog";

const props = defineProps({
  data: {},
});
</script>

<template>
    <div class="dialog__content">
        <div class="dialog__top">
            <button type="button" @click="useDialog().toggle"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <div class="dialog__image">
            <div class="dialog__image-inner">
            <img :src="data.infoBox.image.default" />

            <a
                :href="`https://www.google.com/maps/dir//${data.officeInfo.street}+${data.officeInfo.house_number},+${data.officeInfo.postal_code}+${data.officeInfo.city}/`"
                class="block-link"
                target="_blank"
            >
                <!-- -->
            </a>

            </div>
        </div>
        <div class="dialog__heading">
            <span class="dialog__title" v-if="data.infoBox.title">{{ data.infoBox.title }}</span>
            <p>{{ data.officeInfo.street }} {{ data.officeInfo.house_number }}<br>
            {{ data.officeInfo.postal_code }} {{ data.officeInfo.city }}<span v-if="data.officeInfo.country">,</span> {{ data.officeInfo.country }}</p>
        </div>
        <div class="dialog__main">
          <span class="dialog__subtitle" v-if="data.infoBox.subtitle">{{ data.infoBox.subtitle }}</span>
          <div>
            <div class="dialog__openinghours" v-for="times in data.infoBox.opening_times" :key="times.id" :class="{ 'dialog__openinghours--active': times.active }">
            <span class="dialog__openinhours-day">{{ times.day }}</span>
            <span class="dialog__openinhours-hours">{{ times.times }}</span>
          </div>
          </div>
          </div>
          <div class="dialog__button">
              <button class="btn-solid--primary-600" type="button" @click="useDialog().toggle">Verder winkelen</button>
          </div>
        </div>
</template>

<style lang="scss" scoped>

  dialog {
    &[open] {
      @apply container grid grid-cols-12 gap-6 bg-transparent h-[90vh] p-0;
      @apply lg:px-6;
      @apply sm:px-4 sm:h-screen;
      @apply xs:p-0 xs:m-0 xs:max-w-full xs:h-full xs:max-h-full;

      &:focus-within {
        @apply outline-none;
      }

      &::backdrop {
        @apply bg-black/60;
        backdrop-filter: blur(2px);
      }
    }
  }

  .dialog__content {
    @apply rounded overflow-hidden bg-white;
    @apply md:rounded-none md:h-full md:relative md:z-10 md:flex md:flex-col md:pb-24;
    @apply xs:pb-[72px];
  }

  .dialog__top{
    button {
      @apply text-[16px] text-black h-9 w-9 bg-white shadow-dropdownBox rounded-full flex items-center justify-center absolute top-3 right-3 transition-colors duration-300 z-10;
      @apply md:h-[62px] md:w-[62px] md:shadow-none md:bg-black md:text-white md:rounded-none md:top-0 md:right-0 md:z-20;
      @apply hover:bg-primary-600 hover:text-white;
    }
  }

  .dialog__image-inner {
    @apply aspect-[400_/_200] bg-border relative;
    @apply md:mt-[62px];

    img {
      @apply h-full w-full object-cover;
    }
  }

  .dialog__heading,
  .dialog__main,
  .dialog__button {
    @apply px-9;
    @apply md:px-4;
  }

  .dialog__heading {
    @apply border-b border-solid border-border pt-6 pb-4;
    @apply md:py-4;

    p {
      @apply text-15_28_14_22 text-black;
    }
  }

  .dialog__title {
    @apply font-fontText font-bold text-19_32_17_30 text-black;
    @apply md:absolute md:top-0 md:h-[62px] md:flex md:items-center md:bg-black md:text-white md:w-full md:px-4 md:-mx-4 md:z-10;
  }

  .dialog__main {
    @apply pt-6 pb-6 block;
    @apply md:py-4 md:h-full md:overflow-y-scroll;
  }

  .dialog__subtitle {
    @apply font-fontText text-17_21_14_21 font-bold text-black block;

    + * {
      @apply mt-1;
    }
  }

  .dialog__openinghours {
    @apply flex justify-between flex-wrap gap-x-3;
  }

  .dialog__openinghours--active {
    .dialog__openinhours-day,
    .dialog__openinhours-hours {
      @apply font-bold;
    }
  }

  .dialog__openinhours-day,
  .dialog__openinhours-hours {
    @apply block text-15_30_14_30 text-black;
  }

  .dialog__button{
    @apply pb-9;
    @apply md:py-3 md:shadow-cartFooter md:bg-white md:z-20 md:absolute md:inset-x-0 md:bottom-0;

    button {
      @apply w-full transition-colors duration-300;
    }
  }

</style>

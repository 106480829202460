<template>
  <section class="slider slider--categories" aria-label="Blader door onze categorieen">
    <div class="slider__container">
      <div class="category__title">
        <span class="slider__title">Blader door onze categorieen</span>
      </div>
      <div class="slider__items">
        <div class="slider__swiper swiper swiper--buttons">
            <div class="slider__swiper-wrapper swiper-wrapper">
              <Category
                v-for="category in categories"
                :category
                class="swiper-slide"
              />
            </div>

            <div class="slider__buttons swiper__buttons">
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </div>
        </div>
      </div>
      <div class="slider__button">
        <a :href="url" class="category__link btn-text--primary-600" v-if="url">
          <!-- TODO: Make it multilingual -->
          Bekijk alle categorieen
        </a>
      </div>
    </div>
  </section>

</template>

<script setup>
  import Category from "./Category.vue";

  const props = defineProps({
    categories: Object,
    url: String
  });
</script>

<style lang="scss" scoped>

  .slider__container {
    @apply gap-0;
  }

  .category__title {
    @apply normal-case font-fontTitle font-bold text-20_26_15_20 mb-7 col-span-12;
  }

  .slider__button {
    @apply col-span-12 mt-6;
  }

.category__link {
  @apply font-normal text-16_24_14_18 pl-5 relative;

  &::before {
    @apply content-["\f105"] font-fontAwesome font-bold left-0 absolute;
  }
}
</style>

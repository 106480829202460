<script setup>
  import { ref, onBeforeMount, onMounted, computed } from "vue";
  import { useCartStore } from "../stores/cart";
  import WishlistButton from "../components/Utility/WishlistButton.vue";
  import AddToCartButton from '../components/Utility/AddToCartButton.vue';

  import RBMImage from "./Utility/RBMImage.vue";
	import {appendLanguagePrefixURL} from "../mixins/appendLanguageURL";

  const props = defineProps({
    isPopular: {
      type: Boolean,
      default: false,
    },
    isProfileItem: {
      type: Boolean,
      default: false,
    },
    product: Object,
    showWishlist: {
      type: Boolean,
      default: true,
    },
    showSkeleton: {
      type: Boolean,
      default: true,
    },
  });

  const cartStore = useCartStore();

  const ariaLabelText = ref(productAriaLabelTextCode);
  const prizeExcTax = ref(productExcludingTaxTextCode);
  const prizeIncTax = ref(productIncludingTaxTextCode);
  const isPopularText = ref(productPopularTextCode);
  const inStockText = ref(productInStockTextCode);
  const outOfStockText = ref(productOutOfStockTextCode);
  const uspText = ref(productShippingUspTextCode);
  const selectedProduct = ref(null);

  const addItemToCart = () => {
    cartStore.addItemToCart(props.product, props.quantity)
  };
</script>

<template>
  <article
    class="product__item"
    :class="{
        'product__item--popular swiper-slide': isPopular,
        'product__item--profile swiper-slide': isProfileItem
    }"
    v-if="props.showSkeleton === false"
  >
    <div class="product__item-content">
      <div class="product__item-image notranslate">
        <!-- TODO: Temporary need to find a way for lazy images & optimized images -->

        <figure class="asp-base">
          <RBMImage :image="product.images[0]" size="small" />
        </figure>

        <WishlistButton :item="product" v-if="showWishlist && !isProfileItem" />
        <div class="product__tag--popular" v-if="product.is_popular">
          <span class="product__tag-text">{{ isPopularText }}</span>
        </div>
      </div>
      <div class="product__item-text">
        <span class="product__item-title notranslate">{{ product.title }}</span>

        <div class="product__item-price" v-if="product.show_price === true">
          <span class="product__item-price--exc"><strong>&euro;{{ product.actual_price }}</strong> {{ prizeExcTax }}</span>
          <span class="product__item-price--inc">&euro;{{ product.price_with_tax }} {{ prizeIncTax }}</span>
        </div>
        <div class="product__item-price product__item-price--request" v-else>
          <span class="product__item-price--req"><strong>Prijs op aanvraag</strong></span> <!-- TODO: HARDCODED TEXT -->
        </div>

        <div class="product__item-actionbox" v-if="!isProfileItem">
          <div class="product__item-description">
            <!-- TODO: What is the treshold for stock warning or stock available? Currently set to 5. -->
            <span v-if="product.amount_in_stock > 5" class="product__item-stock">
              <i class="fa-solid fa-circle-check"></i>
              <span>
                <span class="notranslate">{{ Math.floor(product.amount_in_stock) }}&nbsp;</span>
                <span>{{ product.unit.toLowerCase() }} op voorraad</span>
              </span>
            </span>
            <span v-else-if="
                product.amount_in_stock <= 5 && product.amount_in_stock > 0
              " class="product__item-stock product__item-stock--low">
                <i class="fa-solid fa-circle-check"></i>  
                <span>
                  <span class="notranslate">{{ Math.floor(product.amount_in_stock) }}&nbsp;</span>
                  <span>{{ inStockText }}</span>
                </span>
            </span>
            <span v-else class="product__item-stock product__item-stock--out">
              {{ outOfStockText }}
            </span>
            <span v-if="product.amount_in_stock > 0" class="product__item-cta">
              {{ product.delivery_time.in_stock }}
            </span>
            <span v-else class="product__item-cta">
              {{ product.delivery_time.out_of_stock }}
            </span>
          </div>
          <div class="product__item-button notranslate" v-if="product.show_price">
            <AddToCartButton :product="product" v-if="product.show_price" :smallButton="true" />
          </div>
        </div>
      </div>
    </div>

    <a :href="appendLanguagePrefixURL(product.slug.startsWith('/') ? product.slug : '/' + product.slug)" class="block-link"><!-- --></a>
  </article>
  <article class="product__item product__item--skeleton swiper-slide" v-else>
    <div class="product__item-content">
      <div class="product__item-wishlist"></div>
      <div class="product__item-image"></div>
      <div class="product__item-text">
        <span class="product__item-title"></span>

        <div class="product__item-price"></div>

        <div class="product__item-actionbox" v-if="!isProfileItem">
          <div class="product__item-description"></div>
          <div class="product__item-button"></div>
        </div>
      </div>
    </div>

    <a :href="product.url"><!-- --></a>
  </article>
</template>

<style lang="scss" scoped>
@import '../../sass/elements/skeletons.scss';

.product__item {
  @apply py-[18px] relative;
  @apply mob:border-b mob:border-solid mob:border-border;

  &.swiper-slide {
    @apply mob:border-b-0;
  }

  &:has(.block-link:hover) {
    .product__item-title {
      @apply text-primary-600;
    }
  }
}

.product__item-content {
  @apply flex flex-col h-full;
  @apply mob:flex-row mob:gap-x-[18px];
}

.product__item-image {
  @apply relative mb-5 pt-4 flex-[0_0_146px];
  @apply mob:mb-0 mob:pt-3 mob:flex-[0_0_130px];
  @apply xs:flex-[0_0_90px];

  figure {
    @apply relative max-w-[130px];
  }

  .asp-base {
    @apply flex items-center justify-center mx-auto;

    .content {
      @apply relative;
    }

    img {
      @apply h-[130px] w-[130px] object-contain;
      @apply xs:h-[90px] xs:w-[90px];
    }
  }
}


.wishlist__button {
  @apply absolute top-0 right-0;
}

.product__item--popular {
  .product__item-content {
    @apply flex-col;
  }

  .product__tag--popular {
    @apply mob:w-[70px] mob:absolute mob:mx-0;
  }

  .product__item-image{
    @apply mb-3;
  }
}

.product__tag--popular {
  @apply aspect-[80_/_46] absolute left-0 bottom-0 w-20 text-center overflow-hidden flex items-center justify-center z-10;
  @apply mob:w-[70px] mob:relative mob:mx-auto;

  &::before {
    @apply content-[""] absolute inset-0 bg-primary w-screen -z-10;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6.4vw), 0 100%);
  }
}

.product__tag-text {
  @apply font-fontText font-bold text-13_13_11_11 uppercase tracking-[0.33px] block text-white;
}

.product__item-text {
  @apply h-full flex flex-col;
  @apply mob:justify-center mob:w-full;
}

.product__item-title {
  @apply font-fontText text-17_21_15_21 text-black tracking-[-.17px] flex flex-col h-full transition-all duration-300;
  @apply mob:tracking-[-.15px];
}

.product__item-price {
  @apply pt-4;
  @apply mob:pt-1.5;

  &.product__item-price--request {
    @apply text-15_21_12_21 text-[#666666] pt-4;
    @apply mob:pt-1.5;
  }
}

.product__item-price--exc,
.product__item-price--req,
.product__item-price--inc {
  @apply font-fontText text-14_16_12_14 text-text;

  strong {
    @apply text-17_21_14_21 text-black;
  }
}

.product__item-price--inc {
  @apply ml-1;
}

.product__item-price--exc {
  @apply lowercase;
}

.product__item-actionbox {
  @apply flex items-center pt-3 gap-x-2;
  @apply mob:pt-2;

  button {
    @apply relative z-10;
  }
}

.product__item-stock {
  @apply font-fontText font-bold text-15_17_12_14 text-green flex gap-x-1;

  &.product__item-stock--low {
    @apply text-[#DE8C00];
  }

  &.product__item-stock--out {
    @apply text-[#666666];
  }
}

.product__item-cta {
  @apply text-13_18_11_14 text-[#4D4D4D] block mt-0.5;
  @apply mob:mt-1.5;
}

.product__item-button {
  @apply ml-auto;

  button {
    @apply transition-colors duration-300 px-0 py-0 h-[54px] w-[74px] flex items-center justify-center text-[18px] z-10;
  }
}

.product__item--skeleton {

  .product__item-image {
    @apply relative w-[130px] min-h-[130px] mx-auto mb-3;
    @apply mob:w-[90px] mob:min-h-[90px] mob:flex-[0_0_90px] mob:mb-0;
    @extend .skeleton;
  }

  .product__item-wishlist {
    @apply flex w-10 h-10 rounded-full absolute top-0 right-0;
    @extend .skeleton;
  }

  .product__item-title {
    @apply w-full h-[40px];
    @extend .skeleton;
  }

  .product__item-price {
    @apply w-full h-[21px] mt-6;
    @apply mob:mt-2;
    @extend .skeleton;
  }

  .product__item-actionbox {
    @apply flex gap-x-8 mt-3;
    @apply mob:gap-2 mob:mt-2 mob:pt-0;
  }

  .product__item-description {
    @apply h-[52px] w-full;
    // clip-path: polygon(0 0, 100% 0, 100% 49%, 60% 50%, 60% 100%, 0 100%);
    @extend .skeleton;
  }

  .product__item-button {
    @apply w-[74px] h-[52px] flex-[0_0_74px];
    @extend .skeleton;
  }
}

.product__item--popular {
  @apply col-span-3 border-b-0;
}


.product__item--profile {
  .product__item-image {
    @apply xs:flex-[0_0_48px];
  }

  .asp-base {
    img {
      @apply xs:h-[48px] xs:w-[48px];
    }
  }

  .product__item-content {
    @apply mob:flex-row mob:gap-x-3;
  }

  .product__item-title {
    @media screen and (max-width: 600px) {
        @apply overflow-hidden pb-0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
  }
}

.dialog__products {
  .product__item {
    @apply mob:border-none;
  }

  .product__item-content {
    @apply mob:flex-col;
  }

  .product__item-actionbox {
    @apply mob:flex-col mob:items-start mob:gap-3;

    .product__item-button {
      @apply mob:ml-0;
    }
  }

  .product__tag--popular {
    @apply mob:hidden;
  }
}
</style>
